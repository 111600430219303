import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { HeaderScan } from "../../components";
import { Footer } from "../../components";

const ScannerLayout = ({ children }) => {
  return (
    <Fragment>
      <HeaderScan />
      {children}
      <Footer />
    </Fragment>
  );
};

ScannerLayout.propTypes = {
  children: PropTypes.any,
};

export default ScannerLayout;
