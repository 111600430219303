import PropTypes from "prop-types";
import React, { Fragment, Component } from "react";
import { toast } from "react-hot-toast";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

import {
  getDiscountPrice,
  getProductCartQuantity,
} from "../../helpers/product";
import { addToCartDispatch } from "../../redux/actions/cartActions";
import { addToWishlistDispatch } from "../../redux/actions/wishlistActions";

class ShopProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridActivate: true,
      listActivate: false,
    };

    this.setGridActive = this.setGridActive.bind(this);
    this.setListActive = this.setListActive.bind(this);
  }

  setGridActive() {
    this.setState({
      gridActivate: true,
      listActivate: false,
    });
  }

  setListActive() {
    this.setState({
      gridActivate: false,
      listActivate: true,
    });
  }

  render() {
    const { products, wishlistItems, addToWishlist, cartItems, addToCart } =
      this.props;
    const { gridActivate, listActivate } = this.state;
    const { setGridActive, setListActive } = this;
    return (
      <div className="shop-products-area">
        {/* shop layout switcher */}
        <div className="shop-layout-switcher text-right space-mt--15 space-mb--15">
          <div className="container">
            <div>
              <button
                className={`${gridActivate ? "active" : ""}`}
                onClick={() => setGridActive()}
              >
                <ReactSVG
                  src={process.env.PUBLIC_URL + "/assets/img/icons/grid.svg"}
                />
              </button>
            </div>
            <div>
              <button
                className={`${listActivate ? "active" : ""}`}
                onClick={() => setListActive()}
              >
                <ReactSVG
                  src={process.env.PUBLIC_URL + "/assets/img/icons/list.svg"}
                />
              </button>
            </div>
          </div>
        </div>

        {/* shop grid products */}
        <div
          className={`shop-grid-products-wrapper space-mb-m--20 ${
            gridActivate ? "d-block" : "d-none"
          }`}
        >
          <div className="container">
            <div className="row">
              {products &&
                products.map((single) => {
                  const wishlistItem = wishlistItems.filter(
                    (wishlistItem) => wishlistItem.id === single.id
                  )[0];

                  const selectedProductColor = single.variation
                    ? single.variation[0].color
                    : "";
                  const productStock = single.variation
                    ? single.variation[0].stock
                    : single.stock;
                  const quantityCount = 1;
                  const productCartQty = getProductCartQuantity(
                    cartItems,
                    single,
                    selectedProductColor
                  );
                  return (
                    <div className="col-6" key={single.id}>
                      <div className="grid-product space-mb--20">
                        <div className="grid-product__image">
                          <Link
                            to={
                              process.env.PUBLIC_URL + `/product/${single.id}`
                            }
                          >
                            <img
                              src={process.env.PUBLIC_URL + single.image[0]}
                              className="img-fluid"
                              alt=""
                            />
                          </Link>
                          <button
                            className={`icon ${
                              wishlistItem !== undefined ? "active" : ""
                            }`}
                            disabled={wishlistItem !== undefined}
                            onClick={() => addToWishlist(single)}
                          >
                            <ReactSVG src="assets/img/icons/heart-dark.svg" />
                          </button>
                        </div>
                        <div className="grid-product__content">
                          <h3 className="title">
                            <Link
                              to={
                                process.env.PUBLIC_URL + `/product/${single.id}`
                              }
                            >
                              {single.name}
                            </Link>
                          </h3>
                          <span className="category">
                            {single.category.map((item, index, arr) => {
                              return (
                                item + (index !== arr.length - 1 ? ", " : "")
                              );
                            })}
                          </span>
                          <div className="price">
                            {single.discount && single.discount > 0 ? (
                              <Fragment>
                                <span className="main-price mr-1">{`$${single.price}`}</span>
                                <span className="discounted-price">{`$${getDiscountPrice(
                                  single.price,
                                  single.discount
                                )}`}</span>
                              </Fragment>
                            ) : (
                              <span className="discounted-price">{`$${single.price}`}</span>
                            )}
                          </div>
                          <div>
                            {productStock && productStock > 0 ? (
                              <button
                                className={` ${
                                  productCartQty >= productStock
                                    ? "out-of-stock"
                                    : "add-button"
                                }`}
                                onClick={() =>
                                  addToCart(
                                    single,
                                    quantityCount,
                                    selectedProductColor
                                  )
                                }
                                disabled={productCartQty >= productStock}
                              >
                                {productCartQty >= productStock
                                  ? "Out of stock"
                                  : "Add"}
                              </button>
                            ) : (
                              <button className="add-button" disabled>
                                Out of stock
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {/* shop list products */}
        <div
          className={`shop-list-products-wrapper ${
            listActivate ? "d-block" : "d-none"
          }`}
        >
          {products &&
            products.map((single) => {
              const wishlistItem = wishlistItems.filter(
                (wishlistItem) => wishlistItem.id === single.id
              )[0];

              const selectedProductColor = single.variation
                ? single.variation[0].color
                : "";
              const productStock = single.variation
                ? single.variation[0].stock
                : single.stock;
              const quantityCount = 1;
              const productCartQty = getProductCartQuantity(
                cartItems,
                single,
                selectedProductColor
              );
              return (
                <div
                  className="list-product border-bottom--medium"
                  key={single.id}
                >
                  <div className="actions">
                    <button
                      className={`actions__btn add-cart`}
                      disabled={!productStock}
                      onClick={() =>
                        addToCart(single, quantityCount, selectedProductColor)
                      }
                    >
                      <ReactSVG src="assets/img/icons/add.svg" />
                    </button>

                    <button
                      className={`actions__btn add-wishlist ${
                        wishlistItem !== undefined ? "active" : ""
                      }`}
                      disabled={wishlistItem !== undefined}
                      onClick={() => addToWishlist(single)}
                    >
                      <ReactSVG src="assets/img/icons/heart-dark.svg" />
                    </button>
                  </div>
                  <div className="list-product__image">
                    <Link to={process.env.PUBLIC_URL + `/product/${single.id}`}>
                      <img
                        src={process.env.PUBLIC_URL + single.image[0]}
                        className="img-fluid"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="list-product__content">
                    <h3 className="title">
                      <Link
                        to={process.env.PUBLIC_URL + `/product/${single.id}`}
                      >
                        {single.name}
                      </Link>
                    </h3>
                    <span className="category">
                      {single.category.map((item, index, arr) => {
                        return item + (index !== arr.length - 1 ? ", " : "");
                      })}
                    </span>
                    <div className="price">
                      {single.discount && single.discount > 0 ? (
                        <Fragment>
                          <span className="main-price mr-1">{`$${single.price}`}</span>
                          <span className="discounted-price">{`$${getDiscountPrice(
                            single.price,
                            single.discount
                          )}`}</span>
                        </Fragment>
                      ) : (
                        <span className="discounted-price">{`$${single.price}`}</span>
                      )}
                    </div>
                    <p>{single.shortDescription}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

ShopProducts.propTypes = {
  addToWishlist: PropTypes.func,
  products: PropTypes.array,
  wishlistItems: PropTypes.array,
  cartItems: PropTypes.array,
  addToCart: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    wishlistItems: state.wishlistData,
    cartItems: state.cartData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToWishlist: (item) => {
      dispatch(addToWishlistDispatch(item));
    },
    addToCart: (item, quantityCount, selectedProductColor) => {
      dispatch(addToCartDispatch(item, quantityCount, selectedProductColor));
      toast.success(`${item.name} has been added to cart`);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopProducts);
