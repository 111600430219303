import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { search } from "../../helpers/globalFunc";

import {
  getProducts,
  getDiscountPrice,
  getSortedProducts,
  getProductCartQuantity,
} from "../../helpers/product";
import { addToCartDispatch } from "../../redux/actions/cartActions";
import { addToWishlistDispatch } from "../../redux/actions/wishlistActions";

const SearchProducts = ({
  products,
  wishlistItems,
  addToWishlist,
  cartItems,
  addToCart,
}) => {
  const { state } = useLocation();
  const [filteredProducts, setFilteredProducts] = useState(products);

  useEffect(() => {
    if (state?.query) {
      const result = search(products, state.query, state.searchParams);
      const sortedProducts = getSortedProducts(
        products,
        state.searchParams,
        state.query
      );
      setFilteredProducts(result);
    } else {
      setFilteredProducts(products);
    }
  }, [state]);
  return (
    <div className="search-products-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* featured products */}
            <div className="search-products-wrapper space-mt--30 space-mb-m--20">
              <div className="row">
                {filteredProducts.length > 0 &&
                  filteredProducts.map((single) => {
                    const wishlistItem = wishlistItems.filter(
                      (wishlistItem) => wishlistItem.id === single.id
                    )[0];

                    const selectedProductColor = single.variation
                      ? single.variation[0].color
                      : "";
                    const productStock = single.variation
                      ? single.variation[0].stock
                      : single.stock;
                    const quantityCount = 1;
                    const productCartQty = getProductCartQuantity(
                      cartItems,
                      single,
                      selectedProductColor
                    );
                    return (
                      <div className="col-6" key={single.id}>
                        <div className="grid-product space-mb--20">
                          <div className="grid-product__image">
                            <Link
                              to={
                                process.env.PUBLIC_URL + `/product/${single.id}`
                              }
                            >
                              <img
                                src={process.env.PUBLIC_URL + single.image[0]}
                                className="img-fluid"
                                alt=""
                              />
                            </Link>
                            <button
                              className={`icon ${
                                wishlistItem !== undefined ? "active" : ""
                              }`}
                              disabled={wishlistItem !== undefined}
                              onClick={() => addToWishlist(single)}
                            >
                              <ReactSVG src="assets/img/icons/heart-dark.svg" />
                            </button>
                          </div>
                          <div className="grid-product__content">
                            <h3 className="title">
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  `/product/${single.id}`
                                }
                              >
                                {single.name}
                              </Link>
                            </h3>
                            <span className="category">
                              {single.category.map((item, index, arr) => {
                                return (
                                  item + (index !== arr.length - 1 ? ", " : "")
                                );
                              })}
                            </span>
                            <div className="price">
                              {single.discount && single.discount > 0 ? (
                                <Fragment>
                                  <span className="main-price mr-1">{`$${single.price}`}</span>
                                  <span className="discounted-price">{`$${getDiscountPrice(
                                    single.price,
                                    single.discount
                                  )}`}</span>
                                </Fragment>
                              ) : (
                                <span className="discounted-price">{`$${single.price}`}</span>
                              )}
                            </div>
                            <div>
                              {productStock && productStock > 0 ? (
                                <button
                                  className={` ${
                                    productCartQty >= productStock
                                      ? "out-of-stock"
                                      : "add-button"
                                  }`}
                                  onClick={() =>
                                    addToCart(
                                      single,
                                      quantityCount,
                                      selectedProductColor
                                    )
                                  }
                                  disabled={productCartQty >= productStock}
                                >
                                  {productCartQty >= productStock
                                    ? "Out of stock"
                                    : "Add"}
                                </button>
                              ) : (
                                <button className="add-button" disabled>
                                  Out of stock
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SearchProducts.propTypes = {
  addToWishlist: PropTypes.func,
  products: PropTypes.array,
  wishlistItems: PropTypes.array,
  cartItems: PropTypes.array,
  addToCart: PropTypes.func,
};
const mapStateToProps = (state, ownProps) => {
  return {
    products: getProducts(state.productData.products, ownProps.limit),
    wishlistItems: state.wishlistData,
    cartItems: state.cartData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToWishlist: (item) => {
      dispatch(addToWishlistDispatch(item));
    },
    addToCart: (item, quantityCount, selectedProductColor) => {
      dispatch(addToCartDispatch(item, quantityCount, selectedProductColor));
      toast.success(`${item.name} has been added to cart`);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchProducts);
