export const SAVE_USER_PROFILE = "SAVE_USER_PROFILE";
export const USER_LOGOUT = "USER_LOGOUT";

const saveUserProfleSuccess = (userDetails) => ({
  type: SAVE_USER_PROFILE,
  payload: userDetails,
});

const logoutUserSuccess = () => ({
  type: USER_LOGOUT,
  payload: { userType: "" },
});

export const saveUserProfile = (userDetails) => {
  return (dispatch) => {
    dispatch(saveUserProfleSuccess(userDetails));
  };
};

export const userLogout = () => {
  return (dispatch) => {
    dispatch(logoutUserSuccess());
  };
};
