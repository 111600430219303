import { SAVE_USER_PROFILE, USER_LOGOUT } from "../actions/userActions";
const initState = {
  userType: "",
};

const userReducer = (state = initState, action) => {
  if (action.type === SAVE_USER_PROFILE) {
    return action.payload;
  }

  if (action.type === USER_LOGOUT) {
    return action.payload;
  }
  return state;
};

export default userReducer;
