import PropTypes from "prop-types";
import React from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const SearchKeywords = ({ show, searchQuery, closeSearch }) => {
  const { state } = useLocation();

  useEffect(() => {
    closeSearch();
  }, [state]);

  return (
    <div
      className={`search-keyword-area space-xy--15 bg-color--grey2 ${
        show ? "active" : ""
      }`}
      id="search-keyword-box"
    >
      {searchQuery ? (
        <>
          <div className="search-keyword-header space-mb--20">
            <Link
              to={{
                pathname: "/search",
                state: { query: searchQuery, searchParams: "name" },
              }}
              className="search-keyword-header__title"
            >
              Search for {`"${searchQuery}"`} in <strong>Products</strong>{" "}
            </Link>
          </div>
          <div className="search-keyword-header space-mb--20">
            <Link
              to={{
                pathname: "/search",
                state: { query: searchQuery, searchParams: "category" },
              }}
              className="search-keyword-header__title"
            >
              Search for {`"${searchQuery}"`} in <strong>Categories</strong>{" "}
            </Link>
          </div>
        </>
      ) : (
        <div className="search-keyword-header space-mb--20">
          <Link
            to={{
              pathname: "/search",
              state: { query: searchQuery, searchParams: "name" },
            }}
            className="search-keyword-header__title"
          >
            View all products
          </Link>
        </div>
      )}
      <div className="search-keyword-header space-mb--20">
        <h4 className="search-keyword-header__title">Hot Keywords</h4>
      </div>
      <ul className="search-keywords">
        <li>
          <Link
            to={{
              pathname: "/search",
              state: { query: "Bread/Baking", searchParams: "category" },
            }}
          >
            Bread
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: "/search",
              state: { query: "Dairy", searchParams: "category" },
            }}
          >
            Milk
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: "/search",
              state: { query: "Household", searchParams: "category" },
            }}
          >
            Soap
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: "/search",
              state: { query: "Household", searchParams: "category" },
            }}
          >
            Alcohol
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: "/search",
              state: { query: "Meat/Fish", searchParams: "category" },
            }}
          >
            Meat
          </Link>
        </li>
      </ul>
    </div>
  );
};

SearchKeywords.propTypes = {
  show: PropTypes.bool,
};

export default SearchKeywords;
