import React from "react";
import { useContext } from "react";
import { createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../redux/actions/userActions";

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const data = useSelector((state) => state.userData);

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  const dispatch = useDispatch();
  const userData = useContext(UserContext);

  const logout = () => {
    localStorage.removeItem("userId");
    dispatch(userLogout());
  };

  return {
    userData,
    logout,
  };
};
