import {
  ADD_PAYMENT_TRANSACTION,
  DELETE_PAYMENT_TRANSACTION,
} from "../actions/transactionActions";

const initState = [];

const transactionReducer = (state = initState, action) => {
  const transactions = state,
    transactionPayload = action.payload;

  if (action.type === ADD_PAYMENT_TRANSACTION) {
    const paymentMatch = transactions.filter(
      (item) => item.pi === transactionPayload.pi
    )[0];
    if (paymentMatch === undefined) {
      return [...transactions, ...transactionPayload];
    } else {
      return transactions.map((item) =>
        item.pi === transactionPayload.pi
          ? {
              ...item,
              ...transactionPayload,
            }
          : item
      );
    }
  }

  if (action.type === DELETE_PAYMENT_TRANSACTION) {
    return transactions.filter((item) => item.pi !== transactionPayload.pi);
  }

  return state;
};

export default transactionReducer;
