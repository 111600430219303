import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

export const resolver = (object) => {
  const schema = Yup.object().shape(object);
  return yupResolver(schema);
};

export const search = (objectData = [], query = "", searchParams = "") => {
  const result = objectData.filter((data) => {
    const keyVal = data[searchParams];
    const regexSearch = new RegExp(query, "i");
    if (typeof keyVal === "object") {
      return keyVal.some((e) => e.match(regexSearch));
    } else {
      return keyVal.match(regexSearch);
    }
  });
  return result;
};
