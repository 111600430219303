import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { fetchProducts } from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { composeWithDevTools } from "redux-devtools-extension";
import axios from "axios";
import { saveUserProfile } from "./redux/actions/userActions";
import { UserProvider } from "./helpers/providers/userProvider";

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save()))
);

// get product data from other source and set it to redux central state
axios
  .get(process.env.PUBLIC_URL + "/data/product.json")
  .then((response) => store.dispatch(fetchProducts(response.data)))
  .catch((error) => console.log(error));

const userId = window.localStorage.getItem("userId");
axios
  .get(process.env.PUBLIC_URL + "/data/users.json")
  .then((response) => {
    const foundUser = response.data.find((user) => user.id === userId);
    if (foundUser) {
      store.dispatch(saveUserProfile({ userType: "user", profile: foundUser }));
    } else {
      saveUserProfile({ userType: "", profile: {} });
    }
  })
  .catch((error) => console.log(error));

ReactDOM.render(
  <Provider store={store}>
    <UserProvider>
      <App />
    </UserProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
