import React from "react";

import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import { useAuth } from "../../helpers/providers/userProvider";
import { useEffect } from "react";

const linkList = [
  {
    label: "Login / Sign Up",
    route: "/login",
    Icon: (
      <ReactSVG
        src={process.env.PUBLIC_URL + "/assets/img/icons/profile.svg"}
      />
    ),
    allowedUserType: [""],
  },
  {
    label: "My Profile",
    route: "/profile",
    Icon: (
      <ReactSVG
        src={process.env.PUBLIC_URL + "/assets/img/icons/profile.svg"}
      />
    ),
    allowedUserType: ["user"],
  },
  {
    label: "Notifiation",
    route: "/notification",
    Icon: (
      <ReactSVG
        src={process.env.PUBLIC_URL + "/assets/img/icons/notification.svg"}
      />
    ),
    allowedUserType: ["user"],
  },
  {
    label: "All Products",
    route: "/products",
    Icon: (
      <ReactSVG
        src={process.env.PUBLIC_URL + "/assets/img/icons/product.svg"}
      />
    ),
    allowedUserType: ["all"],
  },
  {
    label: "My Order",
    route: "/order",
    Icon: (
      <ReactSVG
        src={process.env.PUBLIC_URL + "/assets/img/icons/cart-two.svg"}
      />
    ),
    allowedUserType: ["all"],
  },
  {
    label: "Cart",
    route: "/cart",
    Icon: (
      <ReactSVG
        src={process.env.PUBLIC_URL + "/assets/img/icons/cart-three.svg"}
      />
    ),
    allowedUserType: ["all"],
  },
  {
    label: "Wishlist",
    route: "/wishlist",
    Icon: (
      <ReactSVG
        src={process.env.PUBLIC_URL + "/assets/img/icons/wishlist.svg"}
      />
    ),
    allowedUserType: ["all"],
  },
  {
    label: "Settings",
    route: "/edit-profile",
    Icon: (
      <ReactSVG
        src={process.env.PUBLIC_URL + "/assets/img/icons/gear-two.svg"}
      />
    ),
    allowedUserType: ["user"],
  },
  {
    label: "Contact Us",
    route: "/contact",
    Icon: (
      <ReactSVG
        src={process.env.PUBLIC_URL + "/assets/img/icons/profile.svg"}
      />
    ),
    allowedUserType: ["all"],
  },
];

const Offcanvas = ({ show, activeStatus }) => {
  const { userData, logout } = useAuth();
  const { userType, profile } = userData;
  useEffect(() => {
    const offcanvasNavigations = document.querySelectorAll(
      ".offcanvas-navigation > li"
    );
    offcanvasNavigations.forEach((single) => {
      single.addEventListener("click", () => {
        activeStatus(false);
      });
    });
  }, [activeStatus]);
  return (
    <div className={`offcanvas-menu ${show ? "active" : ""}`}>
      <div className="profile-card text-center">
        <div className="profile-card__image space-mb--10">
          <img
            src={process.env.PUBLIC_URL + "/assets/img/profile.jpg"}
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="profile-card__content">
          {userType === "user" && (
            <p className="name">
              {profile?.name} <span className="id">ID {profile.id}</span>
            </p>
          )}
          {userType === "guest" && (
            <p className="name">
              Guest <span className="id">ID {profile.id}</span>
            </p>
          )}
        </div>
      </div>
      <div className="offcanvas-navigation-wrapper space-mt--40">
        <ul className="offcanvas-navigation">
          {linkList.map(({ label, route, Icon, allowedUserType }, index) => {
            return allowedUserType.includes(userType) ||
              allowedUserType.includes("all") ? (
              <li key={index}>
                <span className="icon">{Icon}</span>
                <Link to={process.env.PUBLIC_URL + route}>{label}</Link>
              </li>
            ) : null;
          })}
          {userType !== "" && (
            <li
              style={{
                cursor: "pointer",
              }}
            >
              <span className="icon">
                <ReactSVG
                  src={process.env.PUBLIC_URL + "/assets/img/icons/profile.svg"}
                />
              </span>
              <p onClick={logout}>Logout</p>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Offcanvas;
