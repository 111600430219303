import productReducer from "./productReducer";
import wishlistReducer from "./wishlistReducer";
import cartReducer from "./cartReducer";
import transactionReducer from "./transactionReducer";
import userReducer from "./userReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  productData: productReducer,
  wishlistData: wishlistReducer,
  cartData: cartReducer,
  transactionData: transactionReducer,
  userData: userReducer,
});

export default rootReducer;
