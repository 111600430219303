import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import { AiOutlineScan } from "react-icons/ai";

const Footer = ({ cartItems }) => {
  const allProductInCartCount = useMemo(() => {
    const cartQtys = cartItems.map((cart) => cart.quantity);
    // Return sum of all quantity
    return cartQtys.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);
  }, [cartItems]);

  return (
    <footer>
      <div className="footer-nav-wrapper">
        <Link
          to={process.env.PUBLIC_URL + "/home"}
          className="footer-nav-single"
        >
          <div className="menu-wrapper">
            <ReactSVG
              src={process.env.PUBLIC_URL + "/assets/img/icons/home.svg"}
            />
            <span>Home</span>
          </div>
        </Link>
        <Link
          to={process.env.PUBLIC_URL + "/scan"}
          className="footer-nav-single"
        >
          <div className="menu-wrapper">
            <AiOutlineScan size={"2em"} />
            <span>Scan</span>
          </div>
        </Link>
        <Link
          to={process.env.PUBLIC_URL + "/cart"}
          className="footer-nav-single"
        >
          <div className="menu-wrapper ">
            <div className="position-relative">
              <ReactSVG
                src={process.env.PUBLIC_URL + "/assets/img/icons/cart.svg"}
              />
              {allProductInCartCount > 0 && (
                <span
                  className="position-absolute badge rounded-pill bg-danger text-light"
                  style={{
                    right: -8,
                    top: -8,
                  }}
                >
                  {allProductInCartCount}
                </span>
              )}
            </div>
            <span>Cart</span>
          </div>
        </Link>
        <Link
          to={process.env.PUBLIC_URL + "/profile"}
          className="footer-nav-single"
        >
          <div className="menu-wrapper">
            <ReactSVG
              src={process.env.PUBLIC_URL + "/assets/img/icons/profile.svg"}
            />
            <span>Profile</span>
          </div>
        </Link>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  cartItems: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
  };
};
export default connect(mapStateToProps)(Footer);
