import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";

import { Header } from "../../components";
import { Footer } from "../../components";
import { useAuth } from "../../helpers/providers/userProvider";

const DefaultLayout = ({ children }) => {
  const { userData } = useAuth();
  if (!["user", "guest"].includes(userData.userType)) {
    return <Redirect to={"/login"} />;
  }

  return (
    <Fragment>
      <Header />
      {children}
      <Footer />
    </Fragment>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.any,
};

export default DefaultLayout;
