export const ADD_PAYMENT_TRANSACTION = "ADD_PAYMENT_TRANSACTION";
export const DELETE_PAYMENT_TRANSACTION = "DELETE_PAYMENT_TRANSACTION";

//add to cart
export const addPaymentTransaction = (payment) => {
  return (dispatch) => {
    dispatch({
      type: ADD_PAYMENT_TRANSACTION,
      payload: payment,
    });
  };
};

export const deleteTransaction = () => {
  return (dispatch) => {
    dispatch({ type: DELETE_PAYMENT_TRANSACTION });
  };
};
